<template>
    <div class="bg-white p-5">
        <h2 class="text-center ">Jogadores</h2>

        <div class="grid surface-card border-round">

            <div class="lg:col-3 md:col-4 col-6 flex flex-column">
                <label>ID</label>
                <InputText v-model="state.filter.id" />
            </div>

            <div class="lg:col-3 md:col-4 col-6 flex flex-column">
                <label>CPF</label>
                <InputText v-model="state.filter.cpf" />
            </div>

            <div class="lg:col-3 md:col-4 col-6 flex flex-column">
                <label>Nome</label>
                <InputText v-model="state.filter.name" />
            </div>

            <div class="lg:col-3 md:col-4 col-6 flex flex-column">
                <label>Email</label>
                <InputText v-model="state.filter.email" />
            </div>

            <div class="lg:col-3 md:col-4 col-6 flex flex-column">
                <label>Code</label>
                <InputText v-model="state.filter.code" />
            </div>

            <div class="lg:col-3 md:col-4 col-6 flex flex-column">
                <label>Status</label>
                <Dropdown showClear v-model="state.filter.status" :options="state.statusOptions" />
            </div>

            <div class="lg:col-3 md:col-4 col-6 flex flex-column">
                <label>Pesquisar</label>
                <Button class="w-full" type="button" icon="pi pi-search" @click="loadPlayers" />
            </div>

        </div>

        <div class="pt-5 border">

            <DataTable :value="state.players" lazy ref="dt" dataKey="id" :autoLayout="true" @page="onPage($event)"
                showGridlines :rowsPerPageOptions="[5, 10, 20]" paginator :loading="state.loading.players"
                :currentPage="state.currentPage" :rows="state.perPage">

                <Column field="id" header="ID" class="text-center text-xl">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <span>
                                {{ slotProps.data.id }}
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field="cpf" header="CPF" class="text-center text-xl">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <span>
                                {{ slotProps.data.cpf }}
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field="name" header="Nome" class="text-center text-xl">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <span>
                                {{ slotProps.data.name }}
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field="email" header="Email" class="text-center text-xl">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <span>
                                {{ slotProps.data.email }}
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field="code" header="Code" class="text-center text-xl">
                    <template #body="slotProps">
                        <div class="flex flex-column">
                            <span>
                                {{ slotProps.data.code }}
                            </span>
                        </div>
                    </template>
                </Column>

                <Column field="status" header="Status" class="text-center text-xl">
                    <template #body="slotProps">
                        <Badge class="mt-2 mb-2" style="font-size: 1rem"
                            :severity="`${changeStatusBoleanToColor(slotProps.data.status)}`"
                            :value="changeStatusBoleanToText(slotProps.data.status)" />
                    </template>
                </Column>

                <Column class="text-center text-xl">
                    <template #header>
                        Ações
                    </template>
                    <template #body="slotProps">
                        <div class="container-button">

                            <Button type="button" label="Transações" icon="pi pi-eye" icon-pos="right" class="mr-2"
                                @click="openTransactionsModal(slotProps.data)" />

                            <Button v-if="slotProps.data.status == 'BLOCKED'" type="button" label="Liberar"
                                icon="pi pi-undo" icon-pos="right" class="p-button-success"
                                @click="openConfirmationModal(slotProps.data)" />

                            <Button v-else type="button" label="Bloquear" icon="pi pi-ban" icon-pos="right"
                                class="p-button-danger" @click="openConfirmationModal(slotProps.data)" />

                        </div>
                    </template>
                </Column>

                <template #empty>
                    <div class="text-center mt-8">
                        <h3>Nenhum Jogador Registrado</h3>
                    </div>
                </template>
            </DataTable>
        </div>

        <Dialog v-model:visible="state.modalIsOpen" :style="{ width: '550px' }" modal header="Atenção!"
            class="text-center text-2xl" @update:visible="handleClose">

            <Message severity="warn" sticky :closable="false" class="text-lg">

                <h4 v-if="state.player.blocked">
                    Após a confirmação de liberação, o jogador portador do cpf {{ state.player.cpf }}, voltará
                    a
                    ser capaz de realizar qualquer tipo
                    de transação.
                    Tem certeza que deseja liberar o jogador?
                </h4>

                <h4 v-else>
                    Após a confirmação do bloqueio, o jogador portador do cpf {{ state.player.cpf }}, não
                    poderá realizar nenhum
                    tipo
                    de transação.
                    Tem certeza que quer bloquear o jogador?
                </h4>

            </Message>

            <Button class="p-button-warning" :loading="state.loading.tryingToChangePlayerStatus" label="Confirmar"
                @click="checkPlayerStatus" />

        </Dialog>


        <Dialog v-model:visible="state.transactionsModalIsOpen" modal header="Transações" class="text-center text-2xl"
            @update:visible="handleTransactionsModalClose">

            <Transactions :player="state.player" />

        </Dialog>

    </div>
</template>

<script>

import InputText from 'primevue/inputtext'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Message from 'primevue/message'
import Badge from 'primevue/badge'
import Dropdown from 'primevue/dropdown'
import Transactions from '../../pages/Transactions/Transactions.vue'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import services from '../../services'
import useNotificationToast from '../../composables/useNotificationToast'

export default {

    components: { InputText, DataTable, Column, Button, Dialog, Message, Badge, Dropdown, Transactions },

    setup() {

        const state = reactive({

            filter: {
                id: '',
                cpf: '',
                name: '',
                email: '',
                code: '',
                status: '',
            },

            statusOptions: [
                '',
                'Bloqueado',
                'Ativo',
            ],

            currentPage: 1,
            perPage: 5,
            totalRecords: 0,

            players: [],

            player: null,

            loading: {
                players: false,
                tryingToChangePlayerStatus: false
            },

            modalIsOpen: false,
            transactionsModalIsOpen: false
        })

        const store = useStore()
        const route = useRoute()
        const { success, error } = useNotificationToast()

        loadPlayers()

        function loadPlayers() {

            state.loading.players = true

            const params = {
                filter: state.filter,
                page: state.currentPage,
                perPage: state.perPage,
            }

            if (state.filter.status == 'Bloqueado') {
                params.filter.status = 'BLOCKED'
            } else if (state.filter.status == 'Ativo') {
                params.filter.status = 'ACTIVE'
            }

            const token = store.getters.getToken

            services.players.getAll({ clientId: route.params.id, params, token })
                .then((response) => {
                    state.players = response.data.data
                    state.currentPage = response.data.meta.current_page
                    state.totalRecords = response.data.meta.total
                })
                .catch(() => {
                    error("Não foi possível carregar os players.")
                })
                .finally(() => { state.loading.players = false })
        }

        function onPage(event) {
            state.currentPage = event.page + 1
            state.perPage = event.rows
            loadPlayers()
        }

        function changeStatusBoleanToColor(status) {
            return status == 'BLOCKED' ? 'danger' : 'success'
        }

        function changeStatusBoleanToText(status) {
            return status == 'BLOCKED' ? 'Bloqueado' : 'Ativo'
        }

        function openTransactionsModal(player) {

            state.transactionsModalIsOpen = true

            state.player = player

        }

        function openConfirmationModal(player) {

            state.modalIsOpen = true

            state.player = player

        }

        function handleClose() {
            state.modalIsOpen = false
        }

        function handleTransactionsModalClose() {
            state.transactionsModalIsOpen = false
        }

        function checkPlayerStatus() {

            if (state.player.status == "BLOCKED") {
                tryToUnblockPlayer(state.player)
            } else {
                tryToBlockPlayer(state.player)
            }

        }

        function tryToUnblockPlayer(player) {

            state.loading.tryingToChangePlayerStatus = true

            const token = store.getters.getToken

            services.clients.unblockPlayer({ id: route.params.id, cpf: player.cpf, token })
                .then(() => {
                    success("O Jogador foi liberado!")
                })
                .catch(() => {
                    error("Não foi possível liberar o jogador.")
                    state.loading.tryingToChangePlayerStatus = false
                })
                .finally(() => {
                    state.loading.tryingToChangePlayerStatus = false
                    loadPlayers()
                    state.modalIsOpen = false

                })

        }

        function tryToBlockPlayer(player) {

            state.loading.tryingToChangePlayerStatus = true

            const token = store.getters.getToken

            services.clients.blockPlayer({ id: route.params.id, cpf: player.cpf, token })
                .then(() => {
                    success("O Jogador foi bloqueado!")
                })
                .catch(() => {
                    error("Não foi possível bloquear o jogador.")
                    state.loading.tryingToChangePlayerStatus = false
                })
                .finally(() => {
                    state.loading.tryingToChangePlayerStatus = false
                    loadPlayers()
                    state.modalIsOpen = false
                })

        }

        return {
            state,
            changeStatusBoleanToColor,
            changeStatusBoleanToText,
            openTransactionsModal,
            openConfirmationModal,
            handleClose,
            handleTransactionsModalClose,
            checkPlayerStatus,
            tryToBlockPlayer,
            tryToUnblockPlayer,
            onPage,
            loadPlayers
        }

    }

}

</script>
