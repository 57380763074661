<template>
    <div class="grid surface-card border-round p-3 m-1">
        <div class="lg:col-3 md:col-4 col-6 flex flex-column">
            <label>ID</label>
            <InputText v-model="state.filter.id" />
        </div>

        <div class="lg:col-3 md:col-4 col-6 flex flex-column">

            <div class="grid">

                <div class="col-6 pr-0">

                    <label>Intervalo de Datas:</label>
                    <Calendar showButtonBar v-model="stateCalendarFilter.datesInterval" dateFormat="dd/mm/yy"
                        :maxDate="new Date()" selectionMode="range" />

                </div>

                <div class="col-6 pl-0">

                    <label>Filtrar por Períodos:</label>
                    <SplitButton class="w-full" label="Hoje" @click="filterToday"
                        :model="stateCalendarFilter.calendarFilterOptions" />

                </div>
            </div>

        </div>

        <div class="lg:col-3 md:col-4 col-6 flex flex-column">
            <label>Status</label>
            <Dropdown showClear v-model="state.filter.status" optionLabel="name" :options="statusOptions" />
        </div>

        <div class="lg:col-3 md:col-4 col-6 flex flex-column">
            <label>Tipo</label>
            <Dropdown showClear v-model="state.filter.type" optionLabel="name" :options="typesOptions" />
        </div>

        <div class="lg:col-3 md:col-4 col-6 flex flex-column">
            <label>Cliente</label>
            <Dropdown showClear v-model="state.filter.client" optionLabel="name" :options="stateClients.clients" />
        </div>

        <div class="lg:col-3 md:col-4 col-6 flex flex-column">
            <label>Fornecedor</label>
            <InputText type="text" v-model="state.filter.vendorName" />
        </div>

        <div class="lg:col-3 md:col-4 col-6 flex flex-column">
            <label>Valor</label>
            <InputText v-model="state.filter.value" />
        </div>

        <div class="lg:col-3 md:col-4 col-6 flex flex-column">
            <label>Pesquisar</label>
            <Button class="w-full" type="button" icon="pi pi-search" @click="loadTransactions" />
        </div>
    </div>

    <DataTable lazy @page="onPage" :currentPage="state.currentPage" :value="state.transactions"
        :loading="state.loading.transactions" paginator
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink PageLinks"
        :rows="state.perPage" :totalRecords="state.totalRecords" :rowsPerPageOptions="[20, 50, 100]"
        :currentPageReportTemplate="'Exibindo {first} à {last} de {totalRecords} registros'">


        <Column sortable field="id" header="ID" :footer="'TOTAL'">
            <template #body="slotProps">
                {{ slotProps.data.id }}
            </template>
        </Column>

        <Column sortable field="client" header="Cliente">
            <template #body="slotProps">
                {{ slotProps.data.clientName }}
            </template>
        </Column>
        <Column sortable field="vendor" header="Fornecedor">
            <template #body="slotProps">
                {{ slotProps.data.vendorName }}
            </template>
        </Column>
        <Column sortable field="playerName" header="Nome">
            <template #body="slotProps">
                {{ slotProps.data.player.name }}
            </template>
        </Column>
        <Column sortable field="playerEmail" header="Login/Email">
            <template #body="slotProps">
                {{ slotProps.data.player.email }}
            </template>
        </Column>
        <Column sortable field="type" header="Tipo">
            <template #body="slotProps">
                {{ typesLabels[slotProps.data.type] }}
            </template>
        </Column>
        <Column sortable field="amount" header="Valor" :footer="sumField('amount')">
            <template #body="slotProps">
                {{ formatAmountToCurrency(slotProps.data.amount) }}
            </template>
        </Column>
        <Column sortable field="tax" header="Taxa" :footer="sumField('tax')">
            <template #body="slotProps">
                {{ formatAmountToCurrency(slotProps.data.tax) }}
            </template>
        </Column>
        <Column sortable field="status" header="Status">
            <template #body="slotProps">
                <span :class="slotProps.data.status">{{ statusLabels[slotProps.data.status] }}</span>
            </template>
        </Column>
        <Column sortable field="datesInterval" header="Gerado em">
            <template #body="slotProps">
                {{ formatDate(slotProps.data.createdAt) }}
            </template>
        </Column>
        <Column sortable field="fallback" header="Fallback">
            <template #body="slotProps">
                {{ slotProps.data.fallback == 1 || slotProps.data.fallback ? 'SIM' : 'NÃO' }}
            </template>
        </Column>

        <Column>
            <template #header>
                Ações
            </template>
            <template #body="slotProps">
                <div class="container-button">
                    <Button type="button" icon="pi pi-eye" @click="openDetailsModal(slotProps.data)"
                        v-tooltip.left="{ value: 'Vizualizar Detalhes', showDelay: 1000, hideDelay: 300 }" />
                </div>
            </template>
        </Column>

    </DataTable>

    <Dialog v-model:visible="state.isDetailsModalOpen" modal :style="{ width: '800px' }" :header="'Detalhes da Transação'"
        class="text-center text-lg 5px" @update:visible="handleClose">
        <div>


            <div class="flex flex-column justify-content-center">
                <h3> {{ checkIfTransactionTypeIs('REMOVAL', state.modalTransaction) ? 'Retirada' : `Cliente:
                    ${state.clientName}` }}</h3>
            </div>

            <div class="grid w-full mt-3">

                <div class="field col-4 flex flex-column">

                </div>

                <div class="field col-4 flex justify-content-center">
                    <h4 class="font-bold">ID: {{ state.modalTransaction.id }}</h4>
                </div>

                <div class="field col-4 flex flex-column">
                </div>

            </div>

            <div class="grid w-full mt-3">

                <div class="field col-4 flex flex-column">
                    <label>Valor: </label>
                    {{ state.modalTransaction.value }}
                </div>

                <div class="field col-4 flex flex-column">
                    <label>Status Atual: </label>
                    {{ statusLabels[state.modalTransaction.status] }}
                </div>

                <div class="field col-4 flex flex-column">
                    <label>Tipo: </label>
                    {{ typesLabels[state.modalTransaction.type] }}
                </div>

            </div>

            <div v-if="checkIfTransactionTypeIs('WITHDRAW', state.modalTransaction) || checkIfTransactionTypeIs('MANUAL', state.modalTransaction)"
                class="grid w-full mt-3">

                <div class="field col-4 flex flex-column">
                    <div>
                        <label> Tipo de chave PIX: </label>
                        {{ pixKeyTypesLabels[state.modalTransaction.key_pix_type] }}
                    </div>

                </div>

                <div class="field col-4 flex flex-column">
                    <div>
                        <label> CHAVE PIX: </label>
                        {{ state.modalTransaction.key_pix }}
                    </div>
                </div>

                <div class="field col-4 flex flex-column">
                </div>

            </div>

            <div v-if="checkIfTransactionTypeIs('REMOVAL', state.modalTransaction)" class="grid w-full mt-3">

                <div class="field col-4 flex flex-column">

                    <label>ID do Usuário: </label>
                    {{ state.player.code }}
                </div>

                <div class="field col-4 flex flex-column">
                    <label> Login: </label>
                    {{ state.player.email }}
                </div>

                <div class="field col-4 flex flex-column">
                </div>

            </div>

            <div class="grid w-full mt-3">

                <div class="field col-4 flex flex-column">
                    <label>Fornecedor: </label>
                    {{ state.vendorName }}
                </div>

                <div class="field col-4 flex flex-column">
                    <label>Fallback: </label>
                    {{ state.modalTransaction.fallback == 1 || state.modalTransaction.fallback ? 'SIM' : 'NÃO' }}
                </div>

                <div class="field col-4 flex flex-column">
                    <div v-if="state.modalTransaction.fallback == 1 || state.modalTransaction.fallback">
                        <label>Primeira Opção de Fornecedor: </label>
                        {{ state.modalTransaction.first_vendor_option }}
                    </div>
                </div>

            </div>

            <div>
                <div v-if="state.player !== null">
                    <div class="flex w-full flex-column justify-content-center items-center">
                        <h3>{{ checkIfTransactionTypeIs('REMOVAL', state.modalTransaction) ? 'Recebedor: ' : 'Jogador: ' }}
                            {{ state.player.name }}
                        </h3>
                    </div>

                    <div v-if="checkIfTransactionTypeIs('REMOVAL', state.modalTransaction)" class="grid w-full mt-3">
                        <div class="field col-4 flex flex-column">
                            <label>CPF/CNPJ: </label>
                            {{ state.player.cpf }}
                        </div>

                        <div class="field col-4 flex flex-column">
                            <label>Tipo de chave PIX: </label>
                            {{ pixKeyTypesLabels[state.modalTransaction.key_pix_type] }}
                        </div>

                        <div class="field col-4 flex flex-column">
                            <label> CHAVE PIX: </label>
                            {{ state.modalTransaction.key_pix }}
                        </div>
                    </div>

                    <div v-else class="grid w-full mt-3">

                        <div class="field col-4 flex flex-column">
                            <label>CPF: </label>
                            {{ state.player.cpf }}
                        </div>


                        <div class="field col-4 flex flex-column">
                            <label>Email: </label>
                            {{ state.player.name }}
                        </div>

                        <div class="field col-4 flex flex-column">
                            <label>Code: </label>
                            {{ state.player.code }}
                        </div>


                        <div class="grid w-full mt-3">

                            <div class="field col-4 flex flex-column">

                                <label>Status: </label>
                                <Badge class="mt-2 mb-2" style="font-size: 1rem"
                                    :severity="`${changeStatusBoleanToColor(state.player.status)}`"
                                    :value="changeStatusBoleanToText(state.player.status)" />
                            </div>


                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="flex w-full flex-column justify-content-center items-center">
                        <h3>Jogador: Nenhum dado está disponível</h3>
                    </div>
                </div>


            </div>

            <div v-if="state.modalTransaction.type === 'DEPOSIT'">

                <div v-if="state.modalTransaction.qr_code_image"
                    class="w-full flex justify-content-center justify-items-center ">
                    <img :src="formatQRCode(state.modalTransaction.qr_code_image)" width="200" height="200" />
                </div>

            </div>

            <br>
            <h5>Histórico da Transação</h5>
            <DataTable lazy :value="state.transactionHistories">

                <Column sortable field="client" header="Status" style="width: 50%">
                    <template #body="slotProps">
                        {{ statusLabels[slotProps.data.status] }}
                    </template>
                </Column>

                <Column sortable field="created_at" header="Atualizado em" style="width: 50%">
                    <template #body="slotProps">
                        {{ formatDate(slotProps.data.created_at) }}
                    </template>
                </Column>

                <Column sortable field="observation" header="Observação" style="width: 50%">
                    <template #body="slotProps">
                        {{ slotProps.data.observation }}
                    </template>
                </Column>

            </DataTable>
        </div>
    </Dialog>
</template>

<script>

import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import InputText from 'primevue/inputtext'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import { reactive } from 'vue'
import services from '../../services'
import { useStore } from 'vuex'
import useNotificationToast from '../../composables/useNotificationToast'
import Badge from 'primevue/badge'
import SplitButton from 'primevue/splitbutton'
import useCalendarFilter from '../../composables/useCalendarFilter'
import useClients from '../../composables/useClients'
import useEnumLabels from '../../composables/useEnumLabels'
import moment from 'moment'

export default {

    props: {
        player: {
            type: Object,
            default: null
        },
    },

    components: {
        Button,
        Dropdown,
        InputText,
        Calendar,
        DataTable,
        Column,
        Dialog,
        Badge,
        SplitButton,
    },

    setup(props) {

        const state = reactive({
            filter: {
                id: '',
                status: '',
                type: '',
                client: null,
                vendorName: '',
                value: '',
            },

            transactions: [],
            transaction: {},
            modalTransaction: {},
            player: {},
            clientName: '',
            vendorName: '',

            transactionHistories: [],

            isDetailsModalOpen: false,

            loading: {
                transactions: false,
                transactionsDetails: false
            },

            currentPage: 1,
            perPage: 20,
            totalRecords: 0,

        })

        const { typesLabels, statusLabels, typesOptions, statusOptions, pixKeyTypesLabels } = useEnumLabels()
        const { stateCalendarFilter, filterToday } = useCalendarFilter(loadTransactions)

        const store = useStore()
        const { error } = useNotificationToast()

        const { stateClients, loadClientsWithoutPagination } = useClients()
        loadClientsWithoutPagination()

        loadTransactions()

        function loadTransactions() {

            state.loading.transactions = true

            if (state.filter.value == null) {
                state.filter.value = ''
            }

            const params = {
                filter: {
                    ...state.filter,
                    ...stateCalendarFilter
                },
                page: state.currentPage,
                perPage: state.perPage,
                player: props.player
            }

            const token = store.getters.getToken

            services.transactions.getAll({ params, token })
                .then((response) => {
                    state.transactions = response.data.data
                    state.currentPage = response.data.meta.current_page
                    state.totalRecords = response.data.meta.total
                })
                .catch(() => {
                    error("Não foi possível carregar as transações.")
                })
                .finally(() => {
                    state.loading.transactions = false
                })

        }

        function sumField(field) {
            return state.transactions.reduce((a, b) => a + (parseFloat(b[field]) || 0), 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')
        }

        function openDetailsModal(transaction) {

            state.isDetailsModalOpen = true
            state.loading.transactionsDetails = true

            state.clientName = transaction.clientName
            state.vendorName = transaction.vendorName

            const token = store.getters.getToken
            const id = transaction.id

            services.transactions.getByIdWithTransactionHistories({ id, token }).then((response) => {
                state.modalTransaction = response.data.transaction
                state.player = response.data.player
                state.transactionHistories = response.data.history

            })
                .catch(() => {
                    error("Não foi possível carregar os detalhes das transações.")
                })
                .finally(() => {
                    state.loading.transactionsDetails = false
                })
        }

        function handleClose() {
            state.isDetailsModalOpen = false
        }

        function onPage(event) {
            state.currentPage = event.page + 1
            state.perPage = event.rows
            loadTransactions()
        }

        function formatDate(dateString) {

            const date = moment.utc(dateString).format('DD/MM/YYYY HH:mm:ss')

            return date

        }

        function formatQRCode(value) {
            return value.includes('data:image/png;base64,') ? value : 'data:image/png;base64,' + value
        }

        function changeStatusBoleanToColor(status) {
            return status == 'BLOCKED' ? 'danger' : 'success'
        }

        function changeStatusBoleanToText(status) {
            return status == 'BLOCKED' ? 'Bloqueado' : 'Ativo'
        }

        function formatAmountToCurrency(amount) {
            if (!amount) amount = 0
            return parseFloat(amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }

        function checkIfTransactionTypeIs(type, transaction) {
            return transaction.type === type ? true : false
        }


        return {
            state,
            typesLabels,
            statusLabels,
            typesOptions,
            statusOptions,
            pixKeyTypesLabels,
            stateClients,
            stateCalendarFilter,
            filterToday,
            loadTransactions,
            sumField,
            openDetailsModal,
            handleClose,
            onPage,
            formatDate,
            formatQRCode,
            changeStatusBoleanToColor,
            changeStatusBoleanToText,
            formatAmountToCurrency,
            checkIfTransactionTypeIs
        }

    },
}
</script>
